<template>
  <div class="context-display__wrapper">
    <div
      @click="togglable ? (openDropdown = !openDropdown) : null"
      class="context-display">
      <Icon :path="iconPath" :size="20" :key="iconKey" />
      <p>
        <span class="ellipsis text">{{ text }}</span>
        <Icon
          :path="mdiChevronDown"
          :size="12"
          v-if="togglable"
          class="arrow" />
      </p>
    </div>
    <Dropdown
      :width="dropdownWidth"
      v-show="openDropdown"
      :openDropdown="openDropdown"
      @close="this.openDropdown = false">
      <slot name="dropdown"> </slot>
    </Dropdown>
  </div>
</template>

<script>
  import Dropdown from "@/components/Topbar/Dropdown.vue";
  import { mdiChevronDown } from "@mdi/js";
  export default {
    data() {
      return { openDropdown: false, mdiChevronDown };
    },
    components: { Dropdown },
    props: {
      dropdownWidth: { type: String, default: "fit-content" },
      togglable: { type: Boolean, default: true },
      iconPath: { type: String },
      text: { type: String },
      iconKey: { type: Number },
    },
  };
</script>

<style lang="scss">
  .context-display {
    &__wrapper {
      position: relative;
    }
    p {
      @include reset-padding-margin;
      font-size: 0.85rem;
      font-weight: 500;
      @include flex-initial($alignItems: center);
    }

    @include flex-initial($alignItems: center);
    @include themed() {
      color: t($text);
    }

    cursor: pointer;

    .text {
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      word-break: break-all;
      line-height: 1.5em;
      max-width: 140px;
    }

    .mobile-layout & {
      flex-direction: column;
      text-align: center;
      gap: 0;
      p {
        font-size: 0.75rem;
        @include flex-initial($alignItems: center, $gap: 0);

        .text {
          max-width: 100px;
          @media screen and (max-width: $general_mobile_width) {
            font-size: 0.65rem;
            max-width: 50px;
          }

          @media screen and (max-width: $small_mobile_width) {
            display: none;
          }
        }
        .arrow {
          @media screen and (max-width: $small_mobile_width) {
            display: none;
          }
        }
      }
    }
  }
</style>
