<template>
  <div
    class="app-container"
    :class="{
      'mobile-layout': mobileLayout,
      'sidebar-visible': sidebarVisible,
      'has-expandable-panel': hasExpandablePanel,
    }">
    <TopBar @toggleSideMenu="toggleSideMenu" :mobileLayout="mobileLayout" />

    <Sidebar
      :sidebarVisible="sidebarVisible"
      @checkSideMenu="checkSideMenu"
      :key="componentKey"
      ref="sidebar" />

    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component" :key="componentKey"></component>
      </transition>
    </router-view>

    <ModalDialog
      v-if="accountModalVisible"
      class="modal"
      :maxWidth="'900'"
      :verticalLayout="this.mobileLayout"
      @close="accountModalVisible = false">
    </ModalDialog>

    <YesNoDialog
      id="unsavedChanges"
      v-if="showNavigationModal"
      :maxWidth="'400'"
      :nameYes="'Discard'"
      :funcYes="routerDiscardChanges"
      :nameNo="'Cancel'"
      :funcNo="routerCancel">
      <h4>Are you sure?</h4>
      <p>
        It looks like you've been editing something. <br />
        If you leave this page your changes will be lost.
      </p>
    </YesNoDialog>
  </div>
</template>

<script>
  import store from "@/store";
  import router from "@/router";
  import Sidebar from "@/components/Sidebar.vue";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import TopBar from "@/components/TopBar.vue";
  import YesNoDialog from "@/components/Dialogs/YesNoDialog.vue";

  export default {
    data() {
      return {
        mobileLayout: false,
        condensedLayout: false,
        sidebarVisible: false,
        hasExpandablePanel: false,
        accountModalVisible: false,
        componentKey: 0,
      };
    },

    computed: {
      showNavigationModal() {
        return store.getters["unsaved/showNavigationModal"];
      },
    },

    watch: {
      $route() {
        this.componentKey += 1;
      },
    },

    methods: {
      logout() {
        store.dispatch("apiPrivate/logout");
        router.push("/");
      },

      checkScreen() {
        this.windowWidth = window.innerWidth;
        this.windowWidth > 640
          ? (this.mobileLayout = false)
          : (this.mobileLayout = true);
        this.windowWidth > 960
          ? (this.condensedLayout = false)
          : (this.condensedLayout = true);
        if (this.mobileLayout) {
          this.sidebarVisible = false;
        }
        if (!this.mobileLayout) {
          this.sidebarVisible = true;
        }
      },

      toggleSideMenu() {
        this.sidebarVisible = !this.sidebarVisible;
      },

      checkSideMenu() {
        if (this.mobileLayout) {
          this.toggleSideMenu();
        }
      },

      showAccountModal() {
        this.accountModalVisible = true;
      },

      noExpandablePanel() {
        this.hasExpandablePanel = false;
      },

      routerDiscardChanges() {
        store.dispatch("unsaved/discardChanges");
      },

      routerCancel() {
        store.dispatch("unsaved/cancelNavigation");
      },
    },

    created() {
      window.addEventListener("resize", this.checkScreen);
      this.checkScreen();
    },

    beforeMount() {},

    mounted() {},

    components: {
      Sidebar,
      ModalDialog,
      TopBar,
      YesNoDialog,
    },
  };
</script>
