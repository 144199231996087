<template>
  <ContextDisplay :iconPath="mdiAccount" :text="getUsername">
    <template v-slot:dropdown>
      <ul class="app-menu">
        <li class="app-menu_item" @click="$emit('logout')">
          <div class="logout-message">
            <Icon :path="mdiAccountCircle" />
            Sign out
          </div>
        </li>
      </ul></template
    >
  </ContextDisplay>
</template>

<script>
  import { mdiAccount, mdiChevronDown, mdiAccountCircle } from "@mdi/js";
  import { mapGetters } from "vuex";
  import ContextDisplay from "@/components/Topbar/ContextDisplay.vue";

  export default {
    data() {
      return {
        openDropdown: false,
        mdiAccount,
        mdiChevronDown,
        mdiAccountCircle,
      };
    },
    emits: ["logout"],
    computed: {
      ...mapGetters({
        getUsername: "claims/userDisplayName",
      }),
    },
    components: { ContextDisplay },
  };
</script>

<style lang="scss">
  .logout-message {
    @include flex-center;
    padding: 0.5rem;
    text-decoration: underline;

    .user-icon {
      margin: 0.25rem 0.5rem 0 0;
      color: $col_beta-darker;
    }
  }
</style>
