<template>
  <ul class="sidebar app-menu">
    <li
      :class="
        isCurrentRoute('/dashboard') || isCurrentArea('/dashboard')
          ? 'selected'
          : ''
      "
      class="item">
      <div class="app-menu_item row" @click="scopedNavigation('/dashboard')">
        <Icon :path="mdiViewDashboardVariant" />
        Dashboard
      </div>
    </li>
    <li>
      <div
        class="app-menu_item row no-link"
        :class="isCurrentArea('/sales') ? 'selected' : ''">
        <span class="material-icons material-icons-round">
          <Icon :path="mdiReceiptText" />
        </span>
        Sales
      </div>
      <ul class="app-menu">
        <li
          class="app-menu_subitem"
          @click="scopedNavigation('/sales/summary')"
          :class="isCurrentArea('/sales/summary') ? 'selected' : ''">
          Summary
        </li>
        <!-- <li
          class="app-menu_subitem"
          @click="scopedNavigation('/sales/customers')"
          :class="isCurrentArea('/sales/customers') ? 'selected' : ''">
          Customers
        </li> -->
        <li
          class="app-menu_subitem"
          @click="scopedNavigation('/sales/performance')"
          :class="isCurrentArea('/sales/performance') ? 'selected' : ''">
          Performance
        </li>
        <li
          class="app-menu_subitem"
          @click="scopedNavigation('/sales/transactions')"
          :class="isCurrentArea('/sales/transactions') ? 'selected' : ''">
          Transactions
        </li>
      </ul>
    </li>
    <li v-if="this.selectedOperatorType == 'outlet'">
      <div
        class="app-menu_item no-link row"
        :class="isCurrentArea('/menus') ? 'selected' : ''">
        <span class="material-icons material-icons-round">
          <Icon :path="mdiFood" />
        </span>
        Menu Builder
      </div>
      <ul class="app-menu">
        <li
          :class="isCurrentRoute('/menus') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="scopedNavigation('/menus')">
          Menus
        </li>
        <li
          :class="isCurrentRoute('/items') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="scopedNavigation('/items')">
          Items
        </li>
        <li
          :class="isCurrentRoute('/components') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="scopedNavigation('/components')">
          Components
        </li>
      </ul>
    </li>
    <li
      v-if="
        this.selectedOperatorType == 'outlet' ||
        this.selectedOperatorType == 'venue'
      "
      :class="isCurrentArea('/invoice') ? 'selected' : ''"
      class="item">
      <div class="app-menu_item row no-link">
        <span class="material-icons material-icons-round">
          <Icon :path="mdiReceiptText" />
        </span>
        Invoices
      </div>
      <ul class="app-menu">
        <li
          class="app-menu_subitem"
          @click="scopedNavigation('/invoices')"
          :class="isCurrentRoute('/invoices') ? 'selected' : ''">
          Statement
        </li>
      </ul>
    </li>

    <li
      v-if="userRole == 'tt_admin_platform'"
      :class="isCurrentArea('/admin') ? 'selected' : ''"
      class="item">
      <div class="app-menu_item row" @click="navigation('/admin')">
        <Icon :path="mdiLockSmart" />
        Admin
      </div>
      <ul class="app-admin">
        <li
          :class="isCurrentRoute('/admin/users') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="navigation('/admin/users')">
          Users
        </li>
        <li
          :class="isCurrentRoute('/admin/adhoc') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="navigation('/admin/adhoc')">
          Adhocs
        </li>
        <li
          :class="isCurrentRoute('/admin/entities') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="navigation('/admin/entities')">
          Entites
        </li>
        <li
          :class="isCurrentRoute('/admin/reports/invoices') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="navigation('/admin/reports/invoices')">
          Reports: Invoices
        </li>
        <li
          :class="isCurrentRoute('/admin/reports/revenue') ? 'selected' : ''"
          class="app-menu_subitem"
          @click="navigation('/admin/reports/revenue')">
          Reports: Revenue
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import router from "@/router";
  import { mapGetters } from "vuex";
  import {
    mdiViewDashboardVariant,
    mdiReceiptText,
    mdiFood,
    mdiLockSmart,
  } from "@mdi/js";

  export default {
    data() {
      return {
        mdiViewDashboardVariant,
        mdiReceiptText,
        mdiFood,
        mdiLockSmart,
      };
    },

    computed: {
      ...mapGetters({
        userRole: "claims/role",
        selectedOperatorId: "selectedOperator/getId",
        selectedOperatorType: "selectedOperator/getType",
        selectedOperatorName: "selectedOperator/getName",
      }),
    },

    props: {
      sidebarVisible: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["checkSideMenu"],

    methods: {
      checkSideMenu() {
        this.$emit("checkSideMenu");
      },

      isCurrentArea(area) {
        return (
          router.currentRoute.value.name.startsWith(area) ||
          (router.currentRoute.value.meta.area != null &&
            router.currentRoute.value.meta.area.startsWith(area))
        );
      },

      isCurrentRoute(route) {
        return router.currentRoute.value.name == route;
      },

      scopedNavigation(route) {
        if (this.isCurrentRoute(route)) {
          return;
        }

        if (typeof route == typeof "") {
          route = {
            name: route,
          };
        }

        if (route.params == null) {
          route.params = {};
        }

        if (!this.isScopeAvailable()) {
          window.log.error(
            `[❌] Attempted to perform a scoped navigation without a valid operator scope to apply.`
          );

          router.push({ path: "/", hash: "#no-scope" });

          return;
        }

        route.params.operatorType = this.selectedOperatorType;
        route.params.operatorId = this.selectedOperatorId;

        router.push(route);
      },

      navigation(route) {
        if (this.isCurrentRoute(route)) {
          return;
        }

        if (typeof route == typeof "") {
          route = {
            name: route,
          };
        }

        if (route.params == null) {
          route.params = {};
        }

        router.push(route);
      },

      isScopeAvailable() {
        return !(
          // Expressed as a NOT expression as it's clearer
          (
            this.selectedOperatorType == null ||
            isNaN(this.selectedOperatorId) ||
            this.selectedOperatorId <= 0
          )
        );
      },
    },
    getters: {},
  };
</script>

<style lang="scss" scoped>
  .app-menu {
    list-style-type: none;

    &_item {
      @include themed() {
        color: t($g4);
      }

      cursor: pointer;
      list-style-type: none;
      font-size: 1rem;
      text-transform: capitalize;
      gap: 5px;
      padding: 1rem 1rem;

      &.no-link {
        cursor: default;
      }

      a {
        text-decoration: none;
      }
    }

    &_subitem {
      @include themed() {
        color: t($g8);
      }

      cursor: pointer;
      list-style-type: none;
      padding: 0.4rem 0.4rem 0.4rem 29px;
      font-size: 0.8rem;
      text-transform: capitalize;

      a {
        text-decoration: none;
      }
    }

    .selected,
    .selected div,
    .selected div span {
      color: $col_alpha !important;
      cursor: default;
    }
  }

  .app-menu_item.selected,
  .app-menu_subitem.selected,
  .app-menu_item.selected:hover,
  .app-menu_subitem.selected:hover {
    font-weight: 600;
  }

  li.item:not(.selected) .app-menu_item:not(.no-link):hover,
  li.app-menu_subitem:not(.selected):hover {
    @include themed() {
      background: t($g12) !important;
    }
  }

  .subicon {
    position: relative;
    top: 3px;
    padding: 0 4px 0 0;
  }

  .sidebar {
    @include themed() {
      color: t($text);
      background: t($back);
      box-shadow: 0 0 6px t($shadow);
    }

    position: fixed;
    width: $sidebar_width;
    height: calc(100% - 3.5rem);
    z-index: 998;
    overflow-y: auto;
    user-select: none;
    top: $topbar_height;
    margin: 0;
    left: 0;
    transition: left 0.4s ease;

    & > li {
      @include themed() {
        border-bottom: 1px solid t($faded);
      }

      & > ul {
        padding-bottom: 1rem;
      }
    }

    .mobile-layout & {
      left: -300px;
    }

    .mobile-layout.sidebar-visible & {
      left: 0;
    }
  }
</style>
