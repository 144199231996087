<template>
  <ContextDisplay
    dropdownWidth="400px"
    :togglable="getOperators.length > 1"
    :iconPath="typeIcon()"
    :key="typeIconKey"
    :text="getSelectedOperationName">
    <template v-slot:dropdown>
      <h4>
        <span class="type-icon">
          <Icon :path="this.opTypeIconDeterminator(getSelectedOperationType)" />
        </span>
        <span class="name">
          {{ getSelectedOperationName }}
        </span>
      </h4>

      <div class="search" v-if="searchable">
        <div class="search_field">
          <span class="search_icon">
            <Icon :path="mdiMagnify" :size="18" />
          </span>

          <input
            placeholder="Search Operators"
            name="searchOperator"
            id="searchOperator"
            v-model="searchValue" />

          <span
            class="material-icons-round search_clear"
            v-show="searchValue?.length > 0"
            @click="searchValue = null">
            <Icon :path="mdiClose" :size="18" />
          </span>
        </div>
      </div>

      <div class="type-filters">
        <ToggleTag
          id="toggle-venues"
          cssClass="toggle-venue"
          v-model="this.showVenues">
          <Icon :path="this.typeIcon('venue')" :size="14" />
          Venues
        </ToggleTag>
        <ToggleTag
          id="toggle-vendors"
          cssClass="toggle-vendor"
          v-model="this.showVendors">
          <Icon :path="this.typeIcon('vendor')" :size="14" />
          Vendors
        </ToggleTag>
        <ToggleTag
          id="toggle-outlets"
          cssClass="toggle-outlet"
          v-model="this.showOutlets">
          <Icon :path="this.typeIcon('outlet')" :size="14" />
          Outlets
        </ToggleTag>
        <ToggleTag
          id="toggle-couriers"
          cssClass="toggle-courier"
          v-model="this.showCouriers">
          <Icon :path="this.typeIcon('courier')" :size="14" />
          Couriers
        </ToggleTag>
      </div>

      <OperatorSelectorList
        :search="searchValue"
        :types="this.typeArray"
        @selectOperation="this.selectOperation" />
    </template>
  </ContextDisplay>
</template>

<script>
  import { mapGetters } from "vuex";
  import ToggleTag from "@/components/Inputs/ToggleTag";
  import ContextDisplay from "@/components/Topbar/ContextDisplay.vue";
  import OperatorSelectorList from "@/components/Operators/OperatorSelectorList";
  import { opTypeIconDeterminator } from "@/helpers/operatorTypeIconDeterminator.js";
  import store from "@/store";
  import {
    mdiChevronDown,
    mdiMagnify,
    mdiClose,
    mdiInformation,
  } from "@mdi/js";

  export default {
    data() {
      return {
        openDropdown: false,
        searchValue: null,
        typeIconKey: 0,
        showVenues: true,
        showVendors: true,
        showOutlets: true,
        showCouriers: true,
        mdiInformation,
        mdiChevronDown,
        mdiMagnify,
        mdiClose,
      };
    },

    components: {
      OperatorSelectorList,
      ToggleTag,
      ContextDisplay,
    },

    props: {
      searchable: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      ...mapGetters({
        getOperators: "availableOperators/get",
        getSelectedOperationName: "selectedOperator/getName",
        getSelectedOperationType: "selectedOperator/getType",
        getSelectedOperationIdentity: "selectedOperator/getIdentity",
      }),

      typeArray() {
        let result = [];

        if (this.showVenues) {
          result.push("venue");
        }
        if (this.showVendors) {
          result.push("vendor");
        }
        if (this.showOutlets) {
          result.push("outlet");
        }
        if (this.showCouriers) {
          result.push("courier");
        }

        return result;
      },
    },

    watch: {
      getSelectedOperationIdentity() {
        this.openDropdown = false;
        this.typeIconKey++;
      },
    },

    methods: {
      opTypeIconDeterminator,

      toggleDropdown() {
        this.openDropdown = !this.openDropdown;
      },

      selectOperation(selectedOperation) {
        const { type, id } = selectedOperation;

        store.dispatch("selectedOperator/use", selectedOperation);

        // If this is a scoped route, update the scope and redirect
        let route = this.$route;
        if (route.meta.scoped) {
          route.params.operatorType = type;
          route.params.operatorId = id;
          this.$router.push(route);
        }
      },

      typeIcon(operatorType) {
        operatorType ??= this.getSelectedOperationType;
        return this.opTypeIconDeterminator(operatorType);
      },
    },
  };
</script>

<style lang="scss">
  .searchable-single-selector {
    position: relative;
    z-index: 2;

    .unclickable {
      cursor: default;
    }
  }

  .type-filters {
    margin-left: 15px;
    margin-bottom: 0.25rem;
    display: flex;
    gap: 0.25rem;

    label {
      font-size: 0.8rem;
      padding: 0.25rem 0.65rem;

      .mdi-icon {
        float: left;
        position: relative;
        top: 3px;
        margin-right: 3px;

        @media screen and (max-width: $general_mobile_width) {
          display: none;
        }
      }

      @include themed() {
        color: t($g12);
      }

      &.active {
        @include themed() {
          color: t($text);
        }
      }
    }

    .toggle-venue {
      @include themed() {
        background-color: t("venue-disabled");
      }

      &.active {
        @include themed() {
          background-color: t("venue");
        }
      }
    }

    .toggle-vendor {
      @include themed() {
        background-color: t("vendor-disabled");
      }

      &.active {
        @include themed() {
          background-color: t("vendor");
        }
      }
    }

    .toggle-outlet {
      @include themed() {
        background-color: t("outlet-disabled");
      }

      &.active {
        @include themed() {
          background-color: t("outlet");
        }
      }
    }

    .toggle-courier {
      @include themed() {
        background-color: t("courier-disabled");
      }

      &.active {
        @include themed() {
          background-color: t("courier");
        }
      }
    }
  }

  .display {
    @include flex-center;

    p {
      width: 140px;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 0.85rem;

      @include flex-initial($alignItems: center);
    }

    .mobile-layout & {
      flex-direction: column;
      text-align: center;
      gap: 0;
      p {
        font-size: 0.75rem;
      }
    }

    @media screen and (max-width: $vertical_tablet_width) {
      gap: 0.25rem !important;

      p {
        width: 100px;
      }
    }

    @media screen and (max-width: calc($general_mobile_width - 1px)) and (min-width: $small_mobile_width) {
      gap: 0.1rem !important;

      p {
        width: 35px;
      }
    }

    @media screen and (max-width: $small_mobile_width) {
      p {
        display: none;
      }
    }
  }

  .search {
    &_field {
      position: relative;
    }

    &_icon {
      @include themed() {
        color: t($g10);
      }

      position: absolute;
      left: 2rem;
      font-size: 1.2rem;
      top: calc(50% - 0.6rem);
    }

    &_clear {
      @include themed() {
        color: t($g10);
      }

      position: absolute;
      right: 2rem;
      font-size: 1.2rem;
      top: calc(50% - 0.6rem);
    }

    input {
      padding: 0.5rem 2.5rem;
      transition: border 0.2s;
      font-size: 1rem;
      border: 0 !important;
      border-bottom: 2px solid transparent !important;

      &:focus {
        outline: 0;
        border: 0;
        border-bottom: 2px solid $col_alpha !important;
      }
    }
  }
</style>
