import {
  OPERATORTYPEVENUE,
  OPERATORTYPEVENDOR,
  OPERATORTYPECOURIER,
  OPERATORTYPEOUTLET,
  OPERATORTYPEVENUEICON,
  OPERATORTYPEVENDORICON,
  OPERATORTYPECOURIERICON,
  OPERATORTYPEOUTLETICON,
} from "@/constants/operatorTypes.ts";

export const opTypeIconDeterminator = (type) => {
  switch (type) {
    case OPERATORTYPEVENUE:
      return OPERATORTYPEVENUEICON;

    case OPERATORTYPEOUTLET:
      return OPERATORTYPEOUTLETICON;

    case OPERATORTYPECOURIER:
      return OPERATORTYPECOURIERICON;

    case OPERATORTYPEVENDOR:
    default:
      return OPERATORTYPEVENDORICON;
  }
};
