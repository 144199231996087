<template>
  <div class="context-selector">
    <div>
      <ul class="context-selector_list">
        <li
          class="context-option"
          :class="{ selected: this.ifSelected(operator) }"
          v-for="operator in this.getOperatorsFiltered"
          :key="`${operator.type}.${operator.id}`"
          @click="handleSelect(operator.type, operator.id, operator.name)"
        >
          <span class="type-icon">
            <Icon :path="this.opTypeIconDeterminator(operator.type)" />
          </span>
          <span class="name">
            {{ operator.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiCheck } from "@mdi/js";
import Enumerable from "linq";
import { opTypeIconDeterminator } from "@/helpers/operatorTypeIconDeterminator.js";

export default {
  data() {
    return {
      mdiCheck,
    };
  },

  computed: {
    ...mapGetters({
      getOperators: "availableOperators/get",
      getSelectedOperationId: "selectedOperator/getId",
      getSelectedOperationType: "selectedOperator/getType",
    }),

    getOperatorsFiltered() {
      let operators = this.getOperators;

      operators = operators.filter(
        (x) => this.types.includes(x.type) && !this.ifSelected(x)
      );


      if (this.search != null && this.search.length > 0) {

        // 'g' will keep the lastIndex where a match occured, 
        // so the subsequent matches will start from the last used index, instead of 0
        const regex = new RegExp(this.search, "i");

        operators = operators.filter((x) => regex.test(x.name));

      }

      return Enumerable.from(operators)
        .orderBy((x) => this.getTypeValue(x.type))
        .thenBy((x) => x.name)
        .toArray();
    },
  },

  props: {
    search: {
      type: String,
      default: "",
    },

    types: {
      type: Array,
      default: () => ["venue", "vendor", "outlet", "courier"],
    },
  },

  emits: ["selectOperation"],

  methods: {
    opTypeIconDeterminator,

    ifSelected(operator) {
      return (
        operator.type === this.getSelectedOperationType &&
        operator.id === this.getSelectedOperationId
      );
    },

    handleSelect(type, id, name) {
      this.$emit("selectOperation", { type, id, name });
    },

    getTypeValue(type) {
      switch (type) {
        case "venue":
          return 0;
        case "vendor":
          return 10;
        case "outlet":
          return 20;
        case "courier":
          return 30;
      }
    },
  },
};
</script>

<style lang="scss">
.context-selector {
  &_list {
    padding: 0.25rem 0;
    max-height: 250px;
    overflow: auto;

    &:last-child {
      padding-bottom: 0;
    }

    li {
      display: flex;

      .type-icon {
        margin-right: 10px;
      }

      .name {
        flex-grow: 1;
      }
    }
  }

  .context-option {
    cursor: pointer;
    padding: 0.45rem 2.25rem;

    &:hover {
      background: $col_beta-lightest;
    }
  }
}
</style>
