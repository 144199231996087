<template>
  <div class="menubar-button" @click="$emit('click')">
    <Icon :path="iconPath" :size="20" />
    <p v-if="text">
      <span class="ellipsis text">{{ text }}</span>
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      iconPath: {
        type: String,
      },
      text: {
        type: String,
      },
    },
    emits: ["click"],
  };
</script>

<style lang="scss">
  .menubar-button {
    cursor: pointer;
    @include flex-initial($alignItems: center);
    font-size: 0.85rem;
    font-weight: 500;

    p {
      @include reset-padding-margin;
      font-size: 0.85rem;
      font-weight: 500;
      @include flex-initial($alignItems: center);
    }

    .text {
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      word-break: break-all;
      line-height: 1.5em;
      max-width: 100px;
    }

    .mobile-layout & {
      flex-direction: column;
      text-align: center;
      gap: 0;
      p {
        font-size: 0.75rem;
        @include flex-initial($alignItems: center, $gap: 0);

        .text {
          max-width: 80px;
          @media screen and (max-width: $general_mobile_width) {
            font-size: 0.65rem;
            max-width: 50px;
          }
          @media screen and (max-width: $small_mobile_width) {
            display: none;
          }
        }
      }
    }
  }
</style>
