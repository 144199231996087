<template>
  <label
    :id="`${id}-label`"
    :for="id"
    class="unselectable"
    :class="cssClassProcessed"
    @click="toggle()">
    <input type="hidden" :id="id" v-model="checked" />
    <slot />
  </label>
</template>

<script>
  export default {
    data() {
      return {
        checked: this.modelValue,
      };
    },

    props: {
      id: {
        type: String,
      },
      cssClass: {
        type: String,
        default: "",
      },
      modelValue: {
        type: Boolean,
        default: true,
      },
    },

    emits: ["update:modelValue"],

    computed: {
      cssClassProcessed() {
        let result = this.cssClass;

        if (this.checked) {
          result += " active";
        }

        return result;
      },
    },

    methods: {
      toggle() {
        this.checked = !this.checked;

        this.$emit("update:modelValue", this.checked);
      },
    },
  };
</script>

<style lang="scss" scoped>
  label {
    padding: 0 8px;
    border-radius: 8px;
    font-weight: 600;
  }
</style>
