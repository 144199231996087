<template>
  <div id="topbar">
    <div class="menubar">
      <div class="menubar__right">
        <MenuButton
          @click="$emit('toggleSideMenu')"
          v-if="mobileLayout"
          :iconPath="mdiMenu" />

        <router-link class="logo-link" :to="{ path: '/' }">
          <img alt="Tuck Trucks logo" class="logo" :src="getLogo()" />
        </router-link>
      </div>

      <div class="tool-bar">
        <!-- <MenuButton
          @click="redirectToContact"
          :iconPath="mdiLifebuoy"
          text="Support" /> -->

        <OperatorSelector />

        <Account @logout="logout" />
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import OperatorSelector from "@/components/Operators/OperatorSelector.vue";
  import Account from "@/components/Topbar/Account.vue";
  import {
    mdiMenu,
    mdiAccount,
    mdiChevronDown,
    mdiAccountCircle,
    mdiLifebuoy,
  } from "@mdi/js";
  import MenuButton from "./Topbar/MenuButton.vue";

  export default {
    components: {
      OperatorSelector,
      Account,
      MenuButton,
    },

    data() {
      return {
        showModal: "",
        mdiMenu,
        mdiAccount,
        mdiChevronDown,
        mdiAccountCircle,
        mdiLifebuoy,
      };
    },

    methods: {
      logout() {
        store.dispatch("tokens/logout");
        store.dispatch("selectedOperator/clear");
      },

      redirectToContact() {
        window.open("https://www.tucktrucks.co.uk/contact", "_blank");
      },

      getLogo() {
        return this.getCdnFile(
          `/images/logos/tuck-trucks/tt-logo-${
            window.theme?.IsDark ? "white" : "black"
          }.png`
        );
      },
    },

    computed: {
      ...mapGetters({
        getTotalClaims: "availableOperators/getTotal",
        getSelectedOperationName: "selectedOperator/getName",
      }),
    },

    emits: ["swapView", "toggleSideMenu"],

    props: {
      mobileLayout: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="scss">
  .menubar {
    @include themed() {
      color: t($text);
      background: t($back);
      box-shadow: 0 2px 5px t($shadow);
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    z-index: 1000;
    height: $topbar_height;

    &__right {
      @include flex-initial($gap: 1rem);
    }
  }

  .menu {
    display: block;
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
  }

  .logo-link {
    position: relative;
    margin-top: 0;
    transition: left 0.4s;
    left: 0;
    display: flex;
    align-items: center;
  }

  img.logo {
    height: 16px;
    padding: 0;
    margin: 0;
    @media screen and (max-width: $general_mobile_width) {
      height: 14px;
    }

    @media screen and (max-width: $small_mobile_width) {
      height: 12px;
    }
  }

  .swap-view {
    display: none;
    position: absolute;
    top: 12px;
    right: 70px;
    cursor: pointer;

    .mobile-layout & {
      display: block;
    }
  }

  .app-menu {
    &_item {
      cursor: pointer;
    }
  }

  .tool-bar {
    @include flex-initial($alignItems: center, $gap: 1.5rem);
    @media screen and (max-width: $general_mobile_width) {
      gap: 0.5rem;
    }
    @media screen and (max-width: $general_mobile_width) {
      gap: 1rem;
    }
  }
</style>
